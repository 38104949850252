.avatar-Header {
  content: '';
  position: absolute;
  top: 125%;
  left: 50%;
  width: 72px;
  height: 72px;
  transform: translate(-50%, -80%);
  background-image: url('./image/avatar-shannons.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-top: inherit;
  border-top-color: transparent;
  z-index: 11;
  border-radius: 50%;
}

.sg-Header {
  line-height: 1;
}
